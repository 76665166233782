import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { AllAnimationsCombined } from '../components/Animation/Animation'
import { ButtonAsLink } from '../components/Button/Button'
import { StyledLogo } from '../containers/WebApp/Onboarding/styled'
import Images from '../images'
import '../styles/style.scss'


export const NotFound = (props) => {
  const { header, subheader, buttoncta } = props.data.datoCmsNotfound
  const { t } = useTranslation();

  return (
    <>
      <AllAnimationsCombined singlePage />
      <main>

        <section className="hero is-fullheight">
          <div className="hero-head">
            <div className="columns">
              <div className="column is-justify-content-center is-flex" style={{ marginTop: 80, alignItems: 'center', position: 'relative' }}>
                <div>
                  <StyledLogo
                    src={Images.logoYellowWhite}
                    srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
                  />
                </div>

              </div>

            </div>
          </div>
          <div className="hero-body">
            <div className="container">

              <div className="columns is-vcentered">
                <div className="column has-text-centered">
                  <img
                    style={{ minHeight: 200 }}
                    src={Images.notFound}
                    srcSet={`${Images.notFound2x} 2x, ${Images.notFound3x} 3x`}
                  />
                  <h1 className="title is-2 has-text-weight-bold mt-4">
                    {header}
                  </h1>
                  <p className="mt-5">
                    {subheader}
                  </p>
                  <div className="mt-5">
                    <ButtonAsLink path="/">{buttoncta}</ButtonAsLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default NotFound

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsNotfound(locale: {eq: $language}) {
      header
      subheader
      buttoncta
    }
  }
`;